<template>
  <div style="position: relative; height: 400px">
    <div id="map" style=""></div>
  </div>
</template>
<style lang="css"></style>
<script>
import common from './common'
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import center from '@turf/center'
import bbox from '@turf/bbox'
import wicket from 'wicket'
import uuid from 'uuid'
import { MAPBOX_TOKEN } from '../config'

mapboxgl.accessToken = MAPBOX_TOKEN

// Allow deletion of the geometry in the direct_select mode
// https://github.com/mapbox/mapbox-gl-draw/blob/main/src/modes/direct_select.js#L166
MapboxDraw.modes.direct_select.onTrash = function (state) {
  let coordinateRemoved = false
  state.selectedCoordPaths
    .sort((a, b) => b.localeCompare(a, 'en', { numeric: true }))
    .forEach((id) => {
      state.feature.removeCoordinate(id)
      coordinateRemoved = true
    })
  this.fireUpdate()
  state.selectedCoordPaths = []
  this.clearSelectedCoordinates()
  this.fireActionable(state)
  if (state.feature.isValid() === false) {
    this.deleteFeature([state.featureId])
    this.changeMode('simple_select', {})
  }
  // delete geometry if no other change was made
  if (!coordinateRemoved) {
    this.deleteFeature([state.featureId])
    this.changeMode('simple_select', {})
  }
}

function create_mapbox_button(ctx, onclick, icon) {
  ctx._btn = document.createElement('button')
  ctx._btn.className = 'mapboxgl-ctrl-icon ' + (icon || '')
  ctx._btn.type = 'button'
  ctx._btn['aria-label'] = 'Toggle Pitch'
  ctx._btn.onclick = function () {
    onclick(ctx._btn)
  }
  return ctx._btn
}

function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Copy success')
    },
    function (err) {
      console.error('Copy failed :', err)
    }
  )
}
class ButtonGroup {
  constructor(container) {
    this.container = container ? container : document.createElement('div')
    this.buttons = []
  }

  onAdd(map) {}

  onRemove() {
    this.buttons.forEach((b) => this.container.removeChild(b))
  }
}

class GeojsonExport extends ButtonGroup {
  onAdd(map) {
    this.buttons.push(
      create_mapbox_button(
        this,
        function () {
          if (map._drawControl && map._drawControl.getAll().features.length == 1) {
            var feature = map._drawControl.getAll().features[0]
            var wkt = new wicket.Wkt()
            var text = wkt.read(JSON.stringify(feature.geometry)).write()
            copyToClipboard(text)
          }
        },
        'mapboxgl-ctrl-wkt'
      )
    )
    this.buttons.push(
      create_mapbox_button(
        this,
        function () {
          if (map._drawControl && map._drawControl.getAll().features.length == 1) {
            var feature = map._drawControl.getAll().features[0]

            copyToClipboard(JSON.stringify(feature))
          }
        },
        'mapboxgl-ctrl-geojson'
      )
    )

    this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
    this.buttons.forEach((b) => this.container.appendChild(b))
    return this.container
  }
}

class LayerOption extends ButtonGroup {
  onAdd(map) {
    this.buttons.push(this._layerVisibility(map))
    this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'

    this.buttons.forEach((b) => this.container.appendChild(b))
    return this.container
  }

  _layerVisibility(map) {
    return create_mapbox_button(
      this,
      function () {
        const visibility = map.getLayoutProperty('featurescollectionlayer', 'visibility')
        const visible_property = visibility == 'visible' || !visibility ? 'none' : 'visible'
        sessionStorage.setItem('layer_visibility', visible_property)
        map.setLayoutProperty('featurescollectionlayer', 'visibility', visible_property)
        map.setLayoutProperty('featurescollectionlayer2', 'visibility', visible_property)
      },
      'mapboxgl-ctrl-layer'
    )
  }
}

class FocusOption extends ButtonGroup {
  onAdd(map) {
    this.buttons.push(this._geometryFocus(map))
    this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'

    this.buttons.forEach((b) => this.container.appendChild(b))
    return this.container
  }

  _geometryFocus(map) {
    return create_mapbox_button(
      this,
      function () {
        if (map._drawControl && map._drawControl.getAll().features.length == 1) {
          var feature = map._drawControl.getAll().features[0]
          var goto_bbox = bbox(feature)
          map.fitBounds(goto_bbox, { padding: 60 })
        }
      },
      'mapboxgl-ctrl-pin'
    )
  }
}

class ToggleControl {
  constructor(content, placement) {
    this.btn = undefined
    this.content = content
    if (typeof content == 'function' || Array.isArray(content)) {
    } else {
      this.contentConstructor = content
    }
    this.placement = placement || 'top-right'
  }

  onAdd(map) {
    var _this = this
    var container = document.createElement('div')

    let options = create_mapbox_button(
      this,
      function (b) {
        var children = _this.btn
        var content = _this.content
        if (!children) {
          b.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-shrink'
          if (Array.isArray(content)) {
            children = []
            content.forEach((c) => {
              children.push(new c(container))
            })
            children.forEach((c) => {
              c.onAdd(map)
            })
            _this.btn = children
            return
          } else if (typeof content == 'function') {
            children = new content(container)
          } else {
            children = content
          }

          // map.addControl(btn, _this.placement)
          child = children.onAdd(map)
          // container.appendChild(child)
          _this.btn = children
        } else {
          //map.removeControl(_this.btn)
          if (Array.isArray(content)) {
            children.forEach((c) => {
              c.onRemove(map)
            })
          } else {
            children.onRemove(map)
          }
          b.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-expand'
          _this.btn = undefined
        }
      },
      'mapboxgl-ctrl-expand'
    )

    container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
    container.appendChild(options)
    this.container = container
    return container
  }
  onRemove() {
    this.container.remove()
  }
}

export default {
  ...common,
  name: 'Map',
  props: {
    value: String, //display default geometry wkt
    tools: Boolean, //activate tools
    features: Object,
    image: Array,
    openid: Function
  },
  components: {},
  data() {
    return {
      accessToken: MAPBOX_TOKEN,
      mapStyle: 'mapbox://styles/mapbox/',
      mapid: 'outdoors-v11',
      layerId: 'firstLayer',
      sourceId: 'firstSource',
      defaultInput: '',
      mapbox: mapboxgl,
      map: null,
      geom_wkt: null,
      geom_geojson: null,
      draw: undefined,
      drawing: false,
      geometry: undefined,
      wkt: new wicket.Wkt(),
      search_placeholder: 'Search | wkt | geojson'
    }
  },
  computed: {
    ...common.computed
  },
  methods: {
    ...common.methods,
    initialize() {},
    get_feature(wktString) {
      return {
        id: uuid.v4(),
        type: 'Feature',
        properties: {},
        geometry: this.getGeojson(wktString)
      }
    },
    getGeojson(wktString) {
      return this.wkt.read(wktString).toJson()
    },
    showResults(results) {
      for (r of results) {
        id = 'thing'
        this.map.addSource(id, {
          type: 'geojson',
          data: r.feature
        })
        this.map.addLayer({
          id: id,
          type: 'fill',
          source: id,
          layout: {},
          paint: {
            'fill-color': '#0080ff',
            'fill-opacity': 0.2
          }
        })
      }
    },
    mapFocus(map, feature) {
      var goto_bbox = bbox(feature)
      map.fitBounds(goto_bbox, { padding: 60 })
    },
    set_wkt(wkt) {
      // this.$route.query.geometry = wkt
    },
    isValidWkt(query) {
      var wkt = new wicket.Wkt()
      try {
        wkt.read(query)
      } catch (e) {
        return false
      }
      return true
    },

    displayFeaturesCollectionGeoms(featuresCollection) {
      featuresCollection.features.forEach((v, i) => {
        featuresCollection.features[i].properties.product_id = v.id
      })

      var uid_source = 'featurescollectionsource' 
      var uid_layer = 'featurescollectionlayer' 
      var uid_layer2 = 'featurescollectionlayer2'

      if (this.map.getLayer(uid_layer)) {
        this.map.removeLayer(uid_layer)
        this.map.removeLayer(uid_layer2)
        this.map.removeSource(uid_source)
      }

      this.map.addSource(uid_source, {
        type: 'geojson',
        data: featuresCollection,
        generateId: true
      })
      this.map.addLayer({
        id: uid_layer,
        type: 'fill',
        source: uid_source,
        paint: {
          'fill-color': '#000',
          'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.2, 0.1]
        },
        filter: ['==', '$type', 'Polygon'],
        layout: {
          visibility: sessionStorage.getItem('layer_visibility') || 'visible'
        }
      })
      this.map.addLayer({
        id: uid_layer2,
        type: 'line',
        source: uid_source,
        paint: {
          'line-color': '#000',
          'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 2, 0.2],
          'line-opacity': 0.6
        },
        filter: ['==', '$type', 'Polygon'],
        layout: {
          visibility: sessionStorage.getItem('layer_visibility') || 'visible'
        }
      })
      let hoveredStateId = null
      var _this = this

      this.map.on('mousemove', uid_layer, (e) => {
        if (e.features.length > 0) {
          if (hoveredStateId !== null) {
            _this.map.setFeatureState({ source: uid_source, id: hoveredStateId }, { hover: false })
          }
          hoveredStateId = e.features[0].id
          _this.map.setFeatureState({ source: uid_source, id: hoveredStateId }, { hover: true })
        }
      })

      this.map.on('mouseleave', uid_layer, () => {
        if (hoveredStateId !== null) {
          _this.map.setFeatureState({ source: uid_source, id: hoveredStateId }, { hover: false })
        }
        hoveredStateId = null
      })
      this.map.on('click', uid_layer, (e) => {
        if (e.defaultPrevented) {
          return
        }
        e.preventDefault()

        if (!this.drawing) {
          var id = e.features[0].properties.product_id
          this.openid(id)
        }
      })
      this.map.on('draw.modechange', e => {
        if (e.mode == "draw_polygon") {
          this.drawing = true
        } else {
          this.drawing = false
        }
      })  
    }
  },
  mounted() {
    let _this = this
    mapboxgl.accessToken = this.accessToken
    const map = new mapboxgl.Map({
      container: 'map', // container ID
      style: this.mapStyle + this.mapid, // style URL
      center: [0, 50], // starting position [lng, lat]
      zoom: 1 // starting zoom
    })
    this.map = map
    this.map.touchZoomRotate.disableRotation()
    this.map.dragRotate.disable()
    if (this.tools) this.map.addControl(new mapboxgl.FullscreenControl())
    if (this.tools) this.map.addControl(new mapboxgl.NavigationControl())

    function coordinateFeature(geojson, title) {
      return {
        center: center(_this.get_feature(geojson)).geometry.coordinates,
        geometry: geojson,
        place_name: title,
        properties: {
          display_mode: 'geometry'
        },
        type: 'Feature'
      }
    }

    var geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      localGeocoder: function (query) {
        var is_polygon = _this.isValidWkt(query)
        // var is_geojson = false
        if (is_polygon) {
          // find coordinates
          var wkt = new wicket.Wkt()
          wkt.read(query)
          return [coordinateFeature(JSON.stringify(wkt.toJson()), query)]
        }
        return null
      },
      placeholder: this.search_placeholder
    })

    var marker_handler = geocoder._handleMarker
    geocoder._handleMarker = function (selected) {
      if (selected.properties.display_mode == 'geometry') {
        let wkt = new wicket.Wkt()
        _this.geometry = wkt.read(selected.geometry).write()
      } else {
        marker_handler = marker_handler.bind(geocoder)
        marker_handler(selected)
      }
    }

    if (this.tools) this.map.addControl(geocoder, 'top-left')

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: this.tools,
        trash: this.tools
      }
    })
    this.draw = draw
    this.map._drawControl = this.draw

    this.map.addControl(this.draw, 'top-left')
    if (this.tools) {
      this.map.on('draw.create', updateArea)
      this.map.on('draw.delete', deleteArea)
      this.map.on('draw.update', updateArea)

      function updateArea(e) {
        //Delete previous geometry
        for (const f of draw.getAll().features) {
          if (f.id != e.features[0].id) {
            draw.delete([f.id])
            geocoder.clear()
          }
        }
        _this.geom_geojson = JSON.stringify(e.features[0])
        let wkt = new wicket.Wkt()
        _this.geometry = wkt.read(_this.geom_geojson).write()
      }
      function deleteArea(e) {
        _this.geometry = ''
        geocoder.clear()
        //reset search too
      }
      this.toggle_control = new ToggleControl([GeojsonExport, FocusOption], 'top-left')
      this.map.addControl(new LayerOption(), 'top-left')
    }
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.geometry = newVal
      }
    },
    geometry: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit('input', newVal)
        if (!newVal) {
          this.map.removeControl(this.toggle_control)
          return
        } else {
          if (!this.map.hasControl(this.toggle_control) && this.tools)
            this.map.addControl(this.toggle_control, 'top-left')
        }
        this.draw.deleteAll()
        let feature = this.get_feature(newVal)
        this.draw.add(feature)
        var _this = this
        // timeout to bypass current animation if exists
        // better solution is to stop others and run the focus
        setTimeout(function () {
          _this.mapFocus(_this.map, feature)
        }, 200)
      }
    },
    features: function (to, from) {
      if (to != from) {
        if (this.map.isStyleLoaded()) {
          this.displayFeaturesCollectionGeoms(to)
        } else {
          var _this = this
          setTimeout(function () {
            _this.displayFeaturesCollectionGeoms(to)
          }, 200)
        }
      }
    }
  }
}
</script>

<style lang="css">
.mapboxgl-ctrl-layer {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="30" height="30">%3Ctext x="50%" y="50%" dy=".35em" style="font-size: 14px; font-family: \'Helvetica Neue\',Arial,Helvetica,sans-serif; font-weight: bold; text-anchor: middle;">❏%3C/text>%3C/svg>');
}

.mapboxgl-ctrl-expand {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="30" height="30">%3Ctext x="50%" y="50%" dy=".35em" style="font-size: 14px; font-family: \'Helvetica Neue\',Arial,Helvetica,sans-serif; font-weight: bold; text-anchor: middle;">▽%3C/text>%3C/svg>');
}

.mapboxgl-ctrl-shrink {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="30" height="30">%3Ctext x="50%" y="50%" dy=".35em" style="font-size: 14px; font-family: \'Helvetica Neue\',Arial,Helvetica,sans-serif; font-weight: bold; text-anchor: middle;">△%3C/text>%3C/svg>');
}

.mapboxgl-ctrl-wkt {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="30" height="30">%3Ctext x="50%" y="50%" dy=".35em" style="font-size: 9px; font-family: \'Helvetica Neue\',Arial,Helvetica,sans-serif; font-weight: bold; text-anchor: middle;">WKT%3C/text>%3C/svg>');
}
.mapboxgl-ctrl-geojson {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="30" height="30">%3Ctext x="50%" y="50%" dy=".35em" style="font-size: 6px; font-family: \'Helvetica Neue\',Arial,Helvetica,sans-serif; font-weight: bold; text-anchor: middle;">geoJSON%3C/text>%3C/svg>');
}
.mapboxgl-ctrl-pin {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="30" height="30">%3Ctext x="50%" y="50%" dy=".35em" style="font-size: 14px; font-family: \'Helvetica Neue\',Arial,Helvetica,sans-serif; font-weight: bold; text-anchor: middle;">📍%3C/text>%3C/svg>');
}

.mapboxgl-ctrl-geocoder {
  min-width: 220px !important;
}
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
</style>
